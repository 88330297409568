import React from "react"

import Layout from "../components/layout"
import { IMG } from "../const"
import * as styles from "../assets/styles/dope360.module.scss"
import FeedbackSection from "../components/feedback-section"

const dope360 = () => {
  return (
    <Layout pageTitle="Service that covers a lot of the marketing needs of small and medium businesses in data.">
      {() => {
        return (
          <>
            <main className={styles.main}>
              <section className={styles.dope360Top}>
                <div className={styles.wrapper}>
                  <div className={styles.dope360TopContainer}>
                    <div className={styles.topContent}>
                      <div>
                        <div className={styles.topLogoContainer}>
                          <img
                            className={styles.topLogoImg}
                            src={`${IMG.DOPE360}/dope-logo.svg`}
                            width="176px"
                            height="106px"
                            alt="Dope360 logo"
                            loading="lazy"
                          />
                        </div>
                        <div className={styles.sectionText}>
                          <p>
                            Service that covers a lot of the marketing needs of
                            small and medium businesses in data. The target
                            audience of Dope360 is small and medium businesses
                            that mostly have phone call leads. So Dope360
                            provides call tracking service from the third-party
                            partner, additional data about call leads and
                            functionality to create, customize and send
                            postcards to any person in the USA, it allows
                            building yard signs, placing them on the map. Also
                            the system is integrated with several external
                            services.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className={styles.topImgContainer}>
                      <picture>
                        {/* <source srcSet={`${IMG.DOPE360}/dope1.avif,	${IMG.DOPE360}/dope1@2x.avif 2x, ${IMG.DOPE360}/dope1@3x.avif 3x`} type="image/avif"/> */}
                        <source
                          srcSet={`${IMG.DOPE360}/dope1.webp,	${IMG.DOPE360}/dope1@2x.webp 2x,	${IMG.DOPE360}/dope1@3x.webp 3x`}
                          type="image/webp"
                        />
                        <source
                          srcSet={`${IMG.DOPE360}/dope1.png,	${IMG.DOPE360}/dope1@2x.png 2x,	${IMG.DOPE360}/dope1@3x.png 3x`}
                        />
                        <img
                          className={styles.topImg}
                          src={`${IMG.DOPE360}/dope1.png`}
                          width="617"
                          height="420"
                          alt="Dope360"
                          loading="lazy"
                        />
                      </picture>
                    </div>
                  </div>
                </div>
              </section>
              <section className={styles.dope360Info}>
                <div className={styles.wrapper}>
                  <div className={styles.dope360InfoContainer}>
                    <div className={styles.infoContent}>
                      <div>
                        <h2 className={styles.sectionTitle}>Problem</h2>
                        <div className={styles.sectionText}>
                          <p>
                            Users of Dope360 have the opportunity to organize in
                            advance campaigns to send postcards with the
                            necessary information/greetings/special offers to
                            their existing and potential clients and partners.
                            Postcards have a guaranteed and targeted delivery.
                            That is, it is an opportunity to reduce the time to
                            organize mailing by creating a campaign separately
                            as well as an efficient use of funds because Dope360
                            sends only to verified addresses.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className={styles.infoContent}>
                      <div>
                        <h2 className={styles.sectionTitle}>Solution</h2>
                        <div className={styles.sectionText}>
                          <p>
                            We also solve the problem of analyzing the
                            efficiency of your yards and collecting data from
                            potential customers.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              <section className={styles.dope360Stack}>
                <div className={styles.wrapper}>
                  <div className={styles.dope360StackContainer}>
                    <h2 className={styles.mtitle}>Technologies</h2>
                    <ul className={styles.stackList}>
                      <li>
                        <div className={styles.itImgBox}>
                          <img
                            className={styles.itImg}
                            src={`${IMG.ICONS}/logos/mongodb.svg`}
                            alt="Mongo"
                            title="Mongo"
                            loading="lazy"
                            width="80"
                            height="80"
                          />
                        </div>
                        <h3 className={styles.itName}>Mongo DB</h3>
                      </li>
                      <li>
                        <div className={styles.itImgBox}>
                          <img
                            className={styles.itImg}
                            src={`${IMG.ICONS}/logos/nodejs.svg`}
                            alt="NodeJS"
                            title="NodeJS"
                            loading="lazy"
                            width="80"
                            height="80"
                          />
                        </div>
                        <h3 className={styles.itName}>NodeJS</h3>
                      </li>
                      <li>
                        <div className={styles.itImgBox}>
                          <img
                            className={styles.itImg}
                            src={`${IMG.ICONS}/logos/react.svg`}
                            alt="React"
                            title="React"
                            loading="lazy"
                            width="80"
                            height="80"
                          />
                        </div>
                        <h3 className={styles.itName}>ReactJS</h3>
                      </li>
                    </ul>
                  </div>
                </div>
              </section>
              <section className={styles.dope360Contact}>
                <div className={styles.wrapper}>
                  <div className={styles.dope360ContactContainer}>
                    <div className={styles.contactImgContainer}>
                      <picture>
                        {/* <source
                          srcSet={`${IMG.DOPE360}/dope2.avif,	${IMG.DOPE360}/dope2@2x.avif 2x, ${IMG.DOPE360}/dope2@3x.avif 3x`}
                          type="image/avif"
                        /> */}
                        <source
                          srcSet={`${IMG.DOPE360}/dope2.webp,	${IMG.DOPE360}/dope2@2x.webp 2x,	${IMG.DOPE360}/dope2@3x.webp 3x`}
                          type="image/webp"
                        />
                        <source
                          srcSet={`${IMG.DOPE360}/dope2.png,	${IMG.DOPE360}/dope2@2x.png 2x,	${IMG.DOPE360}/dope2@3x.png 3x`}
                        />
                        <img
                          className={styles.contactImg}
                          src={`${IMG.DOPE360}/dope2.png`}
                          width="505"
                          height="383"
                          alt="Dope360 Contacts & Lists"
                          loading="lazy"
                        />
                      </picture>
                    </div>
                    <div className={styles.contactContent}>
                      <div>
                        <h2 className={styles.sectionTitle}>
                          Contacts & Lists
                        </h2>
                        <div className={styles.sectionText}>
                          <p>
                            The user can upload a list of his contacts
                            (customers, friends and etc). Contact addresses are
                            checked for validity, so that postcards will be 100%
                            delivered correctly. Contact lists will be created
                            for further targeted use based on the received calls
                            to the indicated numbers.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              <section className={styles.dope360Template}>
                <div className={styles.wrapper}>
                  <div className={styles.dope360TemplateContainer}>
                    <picture>
                      {/* <source srcSet={`${IMG.DOPE360}/dope3.avif,	${IMG.DOPE360}/dope3@2x.avif 2x, ${IMG.DOPE360}/dope3@3x.avif 3x`} type="image/avif"/> */}
                      <source
                        srcSet={`${IMG.DOPE360}/dope3.webp,	${IMG.DOPE360}/dope3@2x.webp 2x,	${IMG.DOPE360}/dope3@3x.webp 3x`}
                        type="image/webp"
                      />
                      <source
                        srcSet={`${IMG.DOPE360}/dope3.png,	${IMG.DOPE360}/dope3@2x.png 2x,	${IMG.DOPE360}/dope3@3x.png 3x`}
                      />
                      <img
                        className={styles.templateImg}
                        src={`${IMG.DOPE360}/dope3.png`}
                        width="1122"
                        height="596"
                        alt="Dope360 Postcard Template"
                        loading="lazy"
                      />
                    </picture>
                  </div>
                </div>
              </section>
              <section className={styles.dope360Postcards}>
                <div className={styles.wrapper}>
                  <div className={styles.dope360PostcardsContainer}>
                    <div className={styles.postcardsContent}>
                      <div>
                        <h2 className={styles.sectionTitle}>Postcards</h2>
                        <div className={styles.sectionText}>
                          <p>
                            There is a possibility to send a custom postcard a
                            user can choose from numerous available templates
                            and use a postcard builder for customizing
                            accordingly to personal needs: changed predefined
                            postcards (upload images, logos, change text,
                            colors, etc.). And send a postcard therefore to the
                            selected list using printing and mailing service.
                          </p>
                        </div>
                        <h3 className={styles.sectionTitle}>
                          Yard Signs printing service
                        </h3>
                        <div className={styles.sectionText}>
                          <p>
                            A user has options to select the size, amount and
                            type of Yard Sign (One-sided, two-sided) and connect
                            a tracking number.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className={styles.postcardsImgContainer}>
                      <picture>
                        {/* <source srcSet={`${IMG.DOPE360}/dope4.avif,	${IMG.DOPE360}/dope4@2x.avif 2x, ${IMG.DOPE360}/dope4@3x.avif 3x`} type="image/avif"/> */}
                        <source
                          srcSet={`${IMG.DOPE360}/dope4.webp,	${IMG.DOPE360}/dope4@2x.webp 2x,	${IMG.DOPE360}/dope4@3x.webp 3x`}
                          type="image/webp"
                        />
                        <source
                          srcSet={`${IMG.DOPE360}/dope4.png,	${IMG.DOPE360}/dope4@2x.png 2x,	${IMG.DOPE360}/dope4@3x.png 3x`}
                        />
                        <img
                          className={styles.postcardsImg}
                          src={`${IMG.DOPE360}/dope4.png`}
                          width="561"
                          height="391"
                          alt="Dope360 Postcards"
                          loading="lazy"
                        />
                      </picture>
                    </div>
                  </div>
                </div>
              </section>
              <section className={styles.dope360Dashboard}>
                <div className={styles.wrapper}>
                  <div className={styles.dope360DashboardContainer}>
                    <div className={styles.dashboardImgContainer}>
                      <picture>
                        {/* <source srcSet={`${IMG.DOPE360}/dope5.avif,	${IMG.DOPE360}/dope5@2x.avif 2x, ${IMG.DOPE360}/dope5@3x.avif 3x`} type="image/avif"/> */}
                        <source
                          srcSet={`${IMG.DOPE360}/dope5.webp,	${IMG.DOPE360}/dope5@2x.webp 2x,	${IMG.DOPE360}/dope5@3x.webp 3x`}
                          type="image/webp"
                        />
                        <source
                          srcSet={`${IMG.DOPE360}/dope5.png,	${IMG.DOPE360}/dope5@2x.png 2x,	${IMG.DOPE360}/dope5@3x.png 3x`}
                        />
                        <img
                          className={styles.dashboardImg}
                          src={`${IMG.DOPE360}/dope5.png`}
                          width="505"
                          height="275"
                          alt="DOPE360 Dashboard"
                          loading="lazy"
                        />
                      </picture>
                    </div>
                    <div className={styles.dashboardContent}>
                      <div>
                        <h2 className={styles.sectionTitle}>Dashboard</h2>
                        <div className={styles.sectionText}>
                          <p>
                            Users can also place marketing products, on the map
                            for incoming data analysis, either bought in the
                            system or created outside Dope360.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              <section className={styles.dope360Tracking}>
                <div className={styles.wrapper}>
                  <div className={styles.dope360TrackingContainer}>
                    <picture>
                      {/* <source srcSet={`${IMG.DOPE360}/dope6.avif,	${IMG.DOPE360}/dope6@2x.avif 2x, ${IMG.DOPE360}/dope6@3x.avif 3x`} type="image/avif"/> */}
                      <source
                        srcSet={`${IMG.DOPE360}/dope6.webp,	${IMG.DOPE360}/dope6@2x.webp 2x,	${IMG.DOPE360}/dope6@3x.webp 3x`}
                        type="image/webp"
                      />
                      <source
                        srcSet={`${IMG.DOPE360}/dope6.png,	${IMG.DOPE360}/dope6@2x.png 2x,	${IMG.DOPE360}/dope6@3x.png 3x`}
                      />
                      <img
                        className={styles.trackingImg}
                        src={`${IMG.DOPE360}/dope6.png`}
                        width="1122"
                        height="610"
                        alt="DOPE360 Tracking"
                        loading="lazy"
                      />
                    </picture>
                  </div>
                </div>
              </section>
              <section className={styles.dope360Calltracking}>
                <div className={styles.wrapper}>
                  <div className={styles.dope360CalltrackingContainer}>
                    <h2 className={styles.sectionTitle}>
                      Call tracking functionality
                    </h2>
                    <div className={styles.sectionText}>
                      <p>
                        Dope360 is an online tracking tool for marketing
                        campaigns and advertising where customers use a call
                        tracking number to track their audience and enrich this
                        audience with metadata like income, household, address
                        and house ownership (+many more).
                      </p>
                    </div>
                  </div>
                </div>
              </section>
            </main>
            <FeedbackSection number={0} />
          </>
        )
      }}
    </Layout>
  )
}

export default dope360
